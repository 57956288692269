import React from "react";
import { graphql } from "gatsby";
import {
  resolveImage,
  resolveObjectWithImage,
  mergePrismicPreview,
} from "@dataResolvers";
import Page from "../../pageTemplates/Page";

/* eslint react/prop-types: 0 */

export const query = graphql`
  query ($uid: String!) {
    prismicPages(uid: { eq: $uid }) {
      id
      data {
        heading
        image {
          url
          dimensions {
            width
            height
          }
        }
        copy {
          html
        }
        meta {
          title
          description
          image {
            url
            dimensions {
              width
              height
            }
          }
        }
      }
    }
  }
`;

const dataResolver = ({ prismicPages }) => {
  const { image, meta, ...rest } = prismicPages.data;

  return {
    ...rest,
    image: resolveImage(image),
    meta: resolveObjectWithImage(meta[0]),
  };
};

const QueryContainer = ({ data: _data, ...rest }) => {
  const merged = mergePrismicPreview(_data);
  const data = dataResolver(merged);
  return <Page data={data} {...rest} />;
};

export default QueryContainer;
