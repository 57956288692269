import React from "react";
import { DefaultLayout } from "@layout";
import { Section, Container, Text, RichText } from "@base";
import { Hero } from "@organisms";
import { useAppState } from "@state/appState";

/* eslint react/prop-types: 0 */
const Page = ({ data, ...pageProps }) => {
  const [{ layout }] = useAppState();
  const { heading, image, copy, meta } = data;

  return (
    <DefaultLayout {...pageProps} meta={meta}>
      {/* Need a spacer for the header if no image supplied */}
      {image ? (
        <Hero variant="small" image={image} />
      ) : (
        <div style={{ height: layout.breakpoints.sm ? 100 : 64 }} />
      )}

      <Section className="bg-white">
        <Container size="content">
          <Text tag="h1" className="text-black max-w-md mb-2">
            {heading}
          </Text>
        </Container>

        <Container size="content" className="mt-6">
          <RichText html={copy.html} />
        </Container>
      </Section>
    </DefaultLayout>
  );
};

export default Page;
